import React from "react";

const CloseIcon = ({ colour }) => (
  <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.3788 12.5L0.479248 22.3994L2.60059 24.5208L12.5 14.6213L22.3995 24.5208L24.5209 22.3994L14.6213 12.5L24.5209 2.60034L22.3995 0.479004L12.5 10.3784L2.60059 0.479004L0.479248 2.60034L10.3788 12.5Z"
      fill={colour}
    />
  </svg>
);

export default CloseIcon;
