import React from "react";

const HamburgerIcon = ({ colour }) => (
  <svg width="31" height="23" viewBox="0 0 31 23" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1.28316e-07 0L31 0V3L2.56631e-07 3L1.28316e-07 0Z" fill={colour} />
    <path d="M1.28316e-07 10H31V13H2.56631e-07L1.28316e-07 10Z" fill={colour} />
    <path d="M1.28316e-07 20H31V23H2.56631e-07L1.28316e-07 20Z" fill={colour} />
  </svg>
);

export default HamburgerIcon;
