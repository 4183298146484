import React from "react";
import { cn } from "../lib/helpers";
import styles from "./banner.module.css";

const Banner = ({ children, bg }) => {
  const inner = cn(
    styles.inner,
    bg === "lightGeo" && styles.lightGeo,
    bg === "white" && styles.white
  );

  return (
    <section className={styles.banner}>
      <div className={inner}>{children}</div>
    </section>
  );
};

export default Banner;
