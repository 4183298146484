import React from 'react'
import { Link } from 'gatsby'
// import {cn} from '../lib/helpers'

import styles from './button.module.css'

const Button = ({children, to, onClick}) => {
  if (to) {
    return <Link className={styles.button} to='/somewhere'>{children}</Link>
  }
  return <button className={styles.button} onClick={onClick}>{children}</button>
}

export default Button
