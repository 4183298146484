import { Link } from "gatsby";
import React from "react";
import Icon from "./icon";
import styles from "./header.module.css";
import LogoDark from "../images/squire-logo-dark.svg";
import LogoLight from "../images/squire-logo-light.svg";

const Header = ({ toggleNav, darkLight }) => {
  const Logo = darkLight === "light" ? LogoDark : LogoLight;

  return (
    <div className={styles.root}>
      <Link to="/">
        <img src={Logo} alt="Squire Logo" className={styles.brand} />
      </Link>
      <button className={styles.navButton} onClick={toggleNav}>
        <Icon symbol="hamburger" />
      </button>
    </div>
  );
};

export default Header;
