import React from "react";

const ChevronDownIcon = ({ colour }) => (
  <svg width="48" height="14" viewBox="0 0 48 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M24 11L0 0V3L24 14L48 3V0L24 11.0026V11Z"
      fill="white"
    />
  </svg>
);

export default ChevronDownIcon;
