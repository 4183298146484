import React from "react";

import ChevronDownIcon from "./chevronDown";
import HamburgerIcon from "./hamburger";
import CloseIcon from "./close";

function Icon({ symbol, colour }) {
  switch (symbol) {
    case "chevronDown":
      return <ChevronDownIcon colour={colour || "black"} />;
    case "hamburger":
      return <HamburgerIcon colour={colour || "black"} />;
    case "close":
      return <CloseIcon colour={colour || "black"} />;
    default:
      return <span>Unknown icon: {symbol}</span>;
  }
}

export default Icon;
