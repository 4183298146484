import React from "react";
import { Link } from "gatsby";
import { graphql, StaticQuery } from "gatsby";
import BannerWorkWithUs from "./banner-work-with-us";
import styles from "./footer.module.css";
import Logo from "../images/squire-logotext-pink.svg";
import FBLogo from "../images/social/logo-facebook.svg";
import ILogo from "../images/social/logo-instagram.svg";
import TLogo from "../images/social/logo-twitter.svg";
import VLogo from "../images/social/logo-vimeo.svg";
import LKLogo from "../images/social/logo-linkedin.svg";

const socialQuery = graphql`
  query socialQuery {
    site: sanitySiteSettings(_id: { eq: "siteSettings" }) {
      instagramUrl
      linkedInUrl
      facebookUrl
      twitterUrl
      vimeoUrl
    }
  }
`;

function Footer() {
  return (
    <StaticQuery
      query={socialQuery}
      render={({ site }) => {
        return (
          <footer className={styles.footer}>
            <BannerWorkWithUs />
            <div className={styles.inner}>
              <img className={styles.brand} src={Logo} alt="Squire Logo" />
              <ul className={styles.social}>
                <li>
                  <a href={site.facebookUrl}>
                    <img src={FBLogo} alt="Facebook logo" />
                  </a>
                </li>
                <li>
                  <a href={site.vimeoUrl}>
                    <img src={VLogo} alt="Vimeo logo" />
                  </a>
                </li>
                <li>
                  <a href={site.instagramUrl}>
                    <img src={ILogo} alt="Instagram logo" />
                  </a>
                </li>
                <li>
                  <a href={site.twitterUrl}>
                    <img src={TLogo} alt="Twitter logo" />
                  </a>
                </li>
                <li>
                  <a href={site.linkedInUrl}>
                    <img src={LKLogo} alt="LinkedIn logo" />
                  </a>
                </li>
              </ul>
              <div className={styles.address}>66 Old Compton Street, Soho, London, W1D 4UH</div>
              <div className={styles.phone}>07958 358 888</div>
              <div>
                <Link to="/privacy-policy" className={styles.ppLink}>
                  Privacy policy
                </Link>
              </div>
              <div className={styles.legal}>
                All rights reserved - Squire Ltd - {new Date().getFullYear()}
              </div>
            </div>
          </footer>
        );
      }}
    />
  );
}

export default Footer;
