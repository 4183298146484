import React from "react";
import Button from "./button";
import Banner from "./banner";

import styles from "./banner-work-with-us.module.css";
import bannerStyles from "./banner.module.css";

const BannerWorkWithUs = () => {
  const handleClick = () => {
    window.location.href = "mailto:hellosquire@icloud.com";
  };

  return (
    <Banner bg="white">
      <div className={styles.inner}>
        <h2 className={bannerStyles.title}>Work with us</h2>
        <p className={bannerStyles.para}>
          Drop us a line if you’re a nice human and you think we’ll get along. Let’s make some
          magic.
        </p>
        <Button onClick={handleClick}>Get in touch</Button>
      </div>
    </Banner>
  );
};

export default BannerWorkWithUs;
