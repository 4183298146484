import React from "react";
import Icon from "./icon";
import { Link } from "gatsby";
import { cn } from "../lib/helpers";
import styles from "./site-nav.module.css";
import Logo from "../images/squire-logo-dark.svg";

const SiteNav = ({ isVisible, closeNav }) => {
  return (
    <div className={cn(styles.wrapper, isVisible && styles.visibleWrapper)}>
      <Link to="/" className={styles.link}>
        <img src={Logo} alt="Squire Logo" className={styles.brand} />
      </Link>
      <nav>
        <button className={styles.close} onClick={closeNav}>
          <Icon symbol="close" colour="white" />
        </button>
        <ul className={styles.list}>
          <li>
            <Link className={styles.link} to="/about-us">
              Who we are
            </Link>
          </li>
          <li>
            <Link className={styles.link} to="/our-work">
              What we do
            </Link>
          </li>
          <li>
            <Link className={styles.link} to="/meet-our-directors">
              Our directors
            </Link>
          </li>
          <li>
            <Link className={styles.link} to="/news">
              News
            </Link>
          </li>
        </ul>
      </nav>
    </div>
  );
};

export default SiteNav;
