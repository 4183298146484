import React from "react";
import Header from "./header";
import Footer from "./footer";
import SiteNav from "./site-nav";

import "../styles/layout.css";
import styles from "./layout.module.css";

const Layout = ({ children, darkLight }) => {
  const [isNavVisible, setIsNavVisible] = React.useState(false);

  return (
    <>
      <Header toggleNav={() => setIsNavVisible(!isNavVisible)} darkLight={darkLight} />
      <SiteNav isVisible={isNavVisible} closeNav={() => setIsNavVisible(false)} />
      <div className={styles.content}>{children}</div>
      <Footer />
    </>
  );
};

export default Layout;
